body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


#hero {
  background-image: url("./assets/hero-bg.png");
}

.thank-you {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 237px);
  background-image: url("./assets/gold-coins-are-falling-from-gold-coin.jpg");
  background-size: cover;
  padding: 50px 0;
}

.thank-you img {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.thank-you h3 {
  font-size: 36px;
  font-weight: 600;
  color: #FACC15;
  margin-bottom: 10px;
}

.thank-you p {
  font-size: 18px;
  margin-bottom: 25px;
}

.thanks-card {
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  padding: 50px;
  border-radius: 20px;
}